$darkblue:#2E384D;
$white:#fff;
$black:rgb(0, 0, 0);
$blue:#2E5BFF;
$blue_20:rgba(46,91,255,0.2);
$blue_10:rgba(46,91,255,0.1);
$green:#1ab9c9;
$gray:#8798AD;
$green:#33AC2E;
$red:#D63649;
$yellow:#F7C137;
$teal:#00C1D4;
$purple:#8C54FF;
$background:#F4F6FC;
