.bgpic {
    background: url("../../Pics/LogInPic.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    background-position-y: bottom;
    min-height: 100%;
    /* height: 100vh; */
  }
  .fontchange {
    font-family: "Rubik", sans-serif !important;
    font-weight: lighter;
    font-size: 34px;
  }
  .top__login--margin {
    margin-top: 80px;
  }
  .top__signup--margin {
    margin-top: 100px;
  }
  @media screen and (min-width: 750px) and (max-width: 1100px) {
    .fontchange {
      font-size: 29px;
    }
  }
  
  @media screen and (max-width: 780px) {
    .bgpic {
      display: none;
    }
    .form__padding--smallscreen {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
  
  .text--color {
    color: #8798ad;
  }
  .logo--position {
    position: absolute;
    top: 5%;
    left: 9%;
  }
  
  .btncolor {
    background-color: #2E5BFF;
    color: white;
    border: 1px solid #2E5BFF;
  }
  .box1 {
    display: inline-block;
    align-content: center;
    border: 1px solid #3052c219;
    border-radius: 10px;
    margin-left: 1rem;
    margin-right: 1rem;
    background: rgba(255, 255, 255, 0.2);
  }
  
  .box_select {
    background-color: #2e5bff10;
    border: 1px solid #2e5bff;
    transition: 0.2s ease-in;
    border-radius: 10px;
    outline: none;
  }
  .box_icon {
    width: 40px;
    height: 40px;
  }
  
  
  *:focus {
    outline: none;
  }
  