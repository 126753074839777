$second-color:#8798AD;
@import '../Styles/variable.scss';
.logo_area{
  padding: 2rem;
  height: fit-content;
  img{
    width: 8vw;
    min-width: 95px;
    max-width: 150px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sidebar{
  background-color: $white;
  height: 100vh;
  padding-right: 0;
  top: 0 !important;
  .flex-column{
    height: 100%;
    text-align: -webkit-center;
  }

  ul{
    height:77vh !important;
      li{
        padding-left: 1.1em!important;
        width: 100%;
        img{
         width: 1.1em;
         height: 1.1em;
        }
          a{
            color:$gray;
            p{
              margin: 0.4rem 1rem !important;
              @media (max-height: 920px) {
                margin: 0.3em 1em !important; 
              }
            }
          }
          
    }
    li:hover{
      background-color: $blue_20;
      transition: all 0.2s ease;
      a{
        color:$blue;
        text-decoration: none; 
      }
    }
  }
  ul > .active{
    border-left: 3px solid $blue;
    background-color: $blue_20;
    a{
      p{
      color: $blue!important;
      }
    }
  
  }
}

.top-header{
  width: 110%;
  min-height: 20px;
  max-height: 3em;
  background-color: #fff;
  top: 0;
  z-index: 1000;
}
  .main {
    margin-left: 180px; /* Same as the width of the sidenav */
    padding: 0px 15px;
  }
  .header{
    background-color: #fff;
    width: 120%;
    height: 70px;
    
  }
  .main-top{
    background-color: $background;
    width: 100%;
    padding:2%; 
    .top-box{
      background-color: #fff;
      width: 100%;
      height: 9em;
      padding: 10%;
      border: 1px solid rgba(46,91,255,0.1);
      box-shadow: 0px 0px 20px 5px $blue_10;
    }
    p{
      color:$second-color;
    }
  }
  
  .bottom-box{
    background-color: #fff;
    width: 100%;
    border-left: 2px solid #2E5BFF;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    padding: 10px;
    box-shadow: 0px 0px 10px 3px $blue_10;
    height: fit-content;
    span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }
  .bottom-box-instock{
    background-color: #fff;
    width: 100%;
    border-left: 2px solid $purple;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    padding: 10px;
    box-shadow: 0px 0px 10px 3px $blue_10;
    span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }
  .bottom-box-send{
    background-color: #fff;
    width: 100%;
    margin-bottom: 0.5rem;
    border-left: 2px solid $teal;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0px 0px 10px 3px $blue_10;
    span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }
  .bottom-box-delivered{
    background-color: #fff;
    width: 100%;
    border-left: 2px solid $red;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0px 0px 10px 3px $blue_10;
    span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }

  span{
    padding-left: 2%;
    padding-right: 2%;
    
  }

  // parts styling
  .parts-main{
    justify-content: space-between;
    @media (max-width: 1300px) {
      padding:2rem 0%;
      transition: all 0.5s ease;
      }
    transition: all 0.5s ease;
    padding: 2em;    
  }
  .add-part-btn{
    float: right;
    padding: 0.5em 2em;
    margin: 0em 1em;
    font-size: 1em;
  }
  .open-link{
    float:right;
    color: $blue;
    padding: 5px 15px;
    border-radius: 4px;
    background-color: transparent;
    font-size: inherit;      

  }
  .open-link:hover{
    color:$blue;
    background-color: $blue_20;
    text-decoration: none;
    transition: all 0.2s ease;

  }

  .parts-filter{
    justify-self: flex-end;
    align-self: flex-end;

    @media (max-width: 1380px) {
      margin: 0px !important;
      transition: all 0.5s ease-in-out;
      }
    p{
      @media (max-height: 920px) {
        margin: 0px !important;
        transition: all 0.5s ease-in-out;
        }
    }
  z-index: 10;
  height:85vh; 
  overflow:scroll;
  max-height: 95vh;
  border-radius: 5px;
  width: 100%;
  background-color: #fff;
  padding: 1.5rem;
  position: sticky;
  box-shadow: 0px 0px 20px 2px $blue_10;
  button{
    margin-top: 1rem;
  }
  }
  .search-btn{
    width: 100%;
    font-size: inherit;
  }
  .part_card{
    box-shadow: 0px 0px 20px 5px $blue_10;
  }
  .part-detail{
    font-size: 0.9em;
    margin-bottom: 12px;
    border: 0;
    box-shadow: 0px 0px 10px 1px $blue_10;
    padding: 0.5em 1.4em;
   
    p {
        max-width: 35ch;
        overflow: scroll;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    .col{
      padding: 2px !important;
    }
  }
  .part-detail:hover{
    background-color: rgb(250, 250, 250);
    transition: all 0.5s ease;
    cursor: pointer;
  }

  .spinner{
    margin-top: 40%;
    margin-left: 40%;
  }

  // transaction

  .edit-trans{
   
    float: right;
    margin-right: 2%;
    font-size: inherit;
  }
  .delete-trans{
    float: right;
    width: 110px;
    font-size: inherit;
    background: $red;
    border: none;
    border-radius: 5px;
    color: white;
    min-height: 34px;
  }
  .delete-trans:hover{

    background-color: darken($color: $red, $amount: 10)!important;
  }
  .edit-wh{
    width: 170px;
    float: right;
    margin-right: 2%;
  }
 
  .gray_text-slim{
    color: darkgray;
  }
  .react-datepicker__input-container{
    input{
      height: 37px;
      width:100%;
    }
  }
  .react-datepicker-wrapper {
    display: block !important;
    padding: 0;
    border: 0;
}
.files{
  padding: 23px;
    background-color: beige;
}
.save-btn{
  width:100%
}
.hideoverflow{
  overflow: scroll;
  
}
::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  color:transparent
}


.select-search {
  position: relative;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  color:#2E384D ;
  background-color: rgba(224,231,255,0.2);
  border: 1px solid transparent;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: $blue;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: $blue_10;
  color: $blue;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: $blue_20;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: $blue_20;
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.form-group{
  margin-bottom: 0.3rem !important;
  p{
    margin-bottom: 0.8rem;
    margin-top: 0.5rem;
  }
}

[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
 
[type="file"] + label {
  color:$blue;
  background-color: $blue_10;
  background-clip: padding-box;
  border: 1.5px solid #E0E7FF;
  border-radius: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  display: inline-block;
  padding-left: 2rem 4rem;
}
  
[type="file"]:focus + label,
[type="file"] + label:hover {
    background-color: $blue_20;
}
  
[type="file"]:focus + label {
  outline: 1px $blue;
}

#labels{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0px 10px;
  .col{
    padding:0
  }
}

.part-selected{
  border: 1px solid #2ef8ff8e!important;
  background-color: #2ef8ff27!important;
}
