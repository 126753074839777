
.DateInput_input{
    background-color:white;
    font-size: .9em;
    padding:0px 10px;
    width:100%
}
.DateRangePickerInput{
    width:'100%'
}
.DateInput{
    width:50% !important
}

.DateRangePickerInput_arrow_svg{
    display: none;
}