@import 'variable.scss';

html {
  font-size: calc(0.3em + 0.5vw);
}
h1 { font-size: 2em }
h2 { font-size: 1.5em }
h3{font-size: 1.1em;}
span,label,p{
font-size: 1em;
}
body{
  overflow: hidden;
 
}
#filter_module span {
  color: #8798AD;
  font-weight: 500;
}



*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Rubik", sans-serif !important;
  background-color: #f4f6fc !important;
  height: 100% !important;
}

.dropdown_item {
  color: #2E384D;
  font-weight: 700;
  padding: 1rem;
  border-top: 0.5px solid rgba(46, 91, 255, 0.2);
  border-bottom: 0.5px solid rgba(46, 91, 255, 0.2);
}

.dropdown_item:hover {
  background-color: rgba(46, 91, 255, 0.2);
  color: #2E5BFF;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.hideoverflow {
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  color: transparent;
}

.css-1wa3eu0-placeholder{
  font-size: inherit;
}
.css-yk16xz-control{
  padding: 0px !important;
 
  font-size: inherit;
}
.css-2b097c-container{
  padding: 0px;
}

h1,
h2,
span,
h3,
h4,
h5,
p {
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

.gray_text{
  color:$gray;
  }
  .gray_text-slim{
    color:$gray;
    font-weight: 400;
  }
  .blue_text{
    color:$blue
    }
  .black_text{
   color:$darkblue
  }
  .black_text-slim{
    color:$darkblue
   }
  .blue20_text{
   color:$blue_20
  }
  .auto_margin{
    margin: auto;
  }
  .bold{
    font-weight: 500;
  }

.btn-secondary {
  float: right;
  margin-right: 2%;
  background-color: rgba(46, 91, 255, 0.2);
  color: #2E5BFF;
  border: none;
}

.btn-secondary:hover {
  background-color: rgba(0, 47, 219, 0.25);
  color: #2E5BFF;
}

.nobg_btn_red {
  background-color: transparent;
  color: #D63649;
  font-weight: 600;
}

.nobg_btn_blue{
  background-color: transparent;
  border-radius: 5px;
  padding: 9px 14px;
  border: 0px;
  text-emphasis: none;
  color: $blue;
  font-weight: 600;
}
.nobg_btn_blue:hover{
  color: $blue;
  text-decoration: none;
  transition: all 0.3s;
  background-color: $blue_20;
}

.nobg_btn_purple {
  background-color: transparent;
  border-radius: 5px;
  padding: 5px 10px;
  border: 0px;
  color: #8C54FF;
  font-weight: 600;
}

.nobg_btn_purple:hover {
  background-color: rgba(141, 84, 255, 0.3);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.nobg_btn_red:hover {
  background-color: rgba(218, 38, 65, 0.2);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.plus_button {
  position: fixed;
  z-index: 1000;
  bottom: 2rem;
  right: 2rem;
  background-color: #2E5BFF;
  color: white;
  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
  font-size: 40px;
  cursor: pointer;
  border: #2E5BFF;
  -webkit-box-shadow: #2E5BFF 0px 0px 10px 4px;
          box-shadow: #2E5BFF 0px 0px 10px 4px;
}

.plus_button:hover {
  background-color: #0f43ff;
  color: white;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
/*# sourceMappingURL=style.css.map */
.trans-filter-part{
  padding: 1rem;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.timeline{
  overflow: scroll;
  padding: 0px!important;
  min-width: 400px;
  max-height: 30vh;
  p{
    margin: 0!important;
  }
}
.multi-select {
  --rmsc-spacing: 1em!important; /* Spacing */
  --rmsc-height:2.3em !important
}

